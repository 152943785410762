import React from 'react'
import Layout from '../components/layout'
import Hero from '../components/Hero'
import { graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'
import SEO from '../components/seo'

const UsesPage = ({ data }) => {
  const imageSource = getSrc(data.file)

  return (
    <Layout>
      <SEO
        title={'LAURIE ON TECH Uses'}
        description={'Tools Laurie uses'}
        imageSource={imageSource}
        imageAlt={"Laurie's desk"}
      />
      <Hero
        image={data.file.childImageSharp.gatsbyImageData}
        title="LAURIEONTECH'S USES PAGE"
      />
      <section id="about" className="main style1">
        <section className="wrapper style4 container">
          <h2 className="hs h2">HARDWARE</h2>
          <ul className="display-bullets ul">
            <li>Personal Machine: 2019 13" Macbook Pro</li>
            <li>Professional Machine: 2020 13" Macbook Pro</li>
            <li>
              <a
                className="a"
                href="https://www.dell.com/en-us/work/shop/dell-ultrasharp-34-curved-usb-c-hub-monitor-u3421we/apd/210-axqs/monitors-monitor-accessories"
              >
                Dell 34" Curved Ultrasharp Monitor
              </a>
            </li>

            <li>
              <a
                className="a"
                href="https://www.shure.com/en-US/products/microphones/beta_87a"
              >
                Shure Beta 87A Microphone
              </a>
            </li>
            <li>
              <a
                className="a"
                href="https://drop.com/buy/drop-alt-mechanical-keyboard"
              >
                Mechanical Keyboard - Halo Clear switches, Drop ALT, Speciality
                keycaps
              </a>
            </li>
            <li>
              <a
                className="a"
                href="https://www.apple.com/shop/product/MJ2R2LL/A/magic-trackpad-2-silver?fnode=56"
              >
                Apple Magic Trackpad 2
              </a>
            </li>
            <li>
              <a className="a" href="https://www.caldigit.com/ts3-plus/">
                TS3Plus Docking Station
              </a>
            </li>

            <li>
              <a
                className="a"
                href="https://www.elgato.com/en/gaming/key-light"
              >
                Elgato Keylight
              </a>
            </li>
          </ul>
          <h2 className="hs h2">ERGONOMICS</h2>
          <ul className="display-bullets ul">
            <li>
              <a
                className="a"
                href="https://www.fully.com/jarvis-adjustable-height-desk-bamboo.html"
              >
                Jarvis Bamboo Standing Desk with Desk Shelf option
              </a>
            </li>
            <li>
              <a
                className="a"
                href="https://www.autonomous.ai/office-chairs/ergonomic-chair?option20=52"
              >
                Autonomous Desk Chair - Red Apple
              </a>
            </li>
            <li>
              <a
                className="a"
                href="https://www.fully.com/accessories/keyboard-trays/esi-all-fit-keyboard-solution.html"
              >
                ESI All Fit Keyboard Tray
              </a>
            </li>
            <li>
              <a
                className="a"
                href="https://www.amazon.com/Mind-Reader-Adjustable-Height-Ergonomic/dp/B01MSQDE0U/ref=asc_df_B01MSQDE0U/?tag=hyprod-20&linkCode=df0&hvadid=167121005230&hvpos=&hvnetw=g&hvrand=1119465196301045676&hvpone=&hvptwo=&hvqmt=&hvdev=c&hvdvcmdl=&hvlocint=&hvlocphy=9008166&hvtargid=pla-305450012362&psc=1"
              >
                Mind Reader Foot Rest
              </a>
            </li>
            <li>
              <a
                className="a"
                href="https://www.amazon.com/Ergodriven-Not-Flat-Anti-Fatigue-Calculated-Must-Have/dp/B00V3TO9EK"
              >
                Ergodriven Floor Mat
              </a>
            </li>
            <li>
              <a
                className="a"
                href="https://www.amazon.com/Rain-Design-mStand-Laptop-Patented/dp/B000OOYECC/ref=asc_df_B000OOYECC/?tag=hyprod-20&linkCode=df0&hvadid=198093101467&hvpos=&hvnetw=g&hvrand=15358464668340683692&hvpone=&hvptwo=&hvqmt=&hvdev=c&hvdvcmdl=&hvlocint=&hvlocphy=9008166&hvtargid=pla-365326654979&psc=1"
              >
                Rain design Laptop Stand
              </a>
            </li>
          </ul>

          <h2 className="hs h2">CABLE MANAGEMENT</h2>
          <ul className="display-bullets ul">
            <li>
              <a
                className="a"
                href="https://www.containerstore.com/s/office/cable-management/poppin-mini-cable-catch/12d?productId=11005674"
              >
                Mini Cable Catch (Colorful 4 pack)
              </a>
            </li>
            <li>
              <a
                className="a"
                href="https://www.containerstore.com/s/office/cable-management/5-untie-ribbon-cable-ties/12d?productId=11004549"
              >
                7" Untie Ribbon Cable Ties (5" linked here)
              </a>
            </li>
            <li>
              <a
                className="a"
                href="https://www.containerstore.com/s/bluelounge-cablebox/d?productId=10028020&q=cable%20box"
              >
                Cable Box
              </a>
            </li>
          </ul>
          <h2 className="hs h2">SOFTWARE</h2>
          <ul className="display-bullets ul">
            <li>VS Code</li>
            <li>Hub</li>
            <li>iTerm with Oh-my-zsh and Starship.rs</li>
            <li>Screenflow</li>
            <li>Magnet</li>
            <li>Keynote</li>
          </ul>
        </section>
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    file(relativePath: { eq: "headers/desk.jpg" }) {
      childImageSharp {
        gatsbyImageData(aspectRatio: 2.33)
      }
    }
  }
`

export default UsesPage
